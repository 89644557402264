import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getConstant(params = {}) {
  return request({
    url: ENDPOINT.CONSTANT,
    method: 'get',
    params
  })
}

export function getActions(params = {}) {
  return request({
    url: ENDPOINT.ACTIONS,
    method: 'get',
    params
  })
}

export function getManagedResources(params = {}) {
  return request({
    url: ENDPOINT.MANAGED_RESOURCES,
    method: 'get',
    params
  })
}

