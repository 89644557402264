export default {
  HOTLINE: '0123456789',
  APP_TITLE: process.env.VUE_APP_TITLE,
  APP_LOGO_TITLE: process.env.VUE_APP_LOGO_TITLE,
  APP_MENUS: process.env.VUE_APP_MENUS,
  USER_ROLE_KEYS: process.env.VUE_APP_USER_ROLES,
  EDITOR_API_KEY: '1oo1lu0sbcnrznvh20hr6tatwezj2zflplh17eataa0p4kft',
  GOOGLE_API_KEY: 'ULQe0gEWUf1eXx7tGsTeBjd_TXzVsYIiake4y1OHKWE',
  POSITION_IDS: [1, 2, 3, 4],
  PAGE_IDS: [
    {
      id: 'home',
      link: 'https://danhgiasuky.vn'
    },
    {
      id: 'post',
      link: 'https://danhgiasuky.vn/post'
    },
    {
      id: 'library',
      link: 'https://danhgiasuky.vn/library'
    },
    {
      id: 'hierarchy',
      link: 'https://danhgiasuky.vn/hierarchy'
    },
    {
      id: 'buddhist-learning',
      link: 'https://danhgiasuky.vn/buddhist-learning'
    },
    {
      id: 'culture-space',
      link: 'https://danhgiasuky.vn/culture-space'
    }
  ],
  ACTIONS: [
    {
      key: 'GET',
      label: 'Xem thông tin'
    },
    {
      key: 'CREATE',
      label: 'Thêm thông tin'
    },
    {
      key: 'UPDATE',
      label: 'Sửa thông tin'
    },
    {
      key: 'DELETE',
      label: 'Xóa thông tin'
    }
  ]
}
