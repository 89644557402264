const state = {
  reloadFoodGroup: ''
}

const getters = {
  reloadFoodGroup(state) {
    return state.reloadFoodGroup
  }
}

const mutations = {
  UPDATE_RELOAD_FOOD_GROUP(state, reloadFoodGroup) {
    state.reloadFoodGroup = reloadFoodGroup
  }
}

const actions = {
  updateReloadFoodGroup({ commit }, reloadFoodGroup) {
    commit('UPDATE_RELOAD_FOOD_GROUP', reloadFoodGroup)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
