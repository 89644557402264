import Vue from 'vue'
import VueRouter from 'vue-router'
// import CONSTANTS from '@/config/constants'
import { getAccountRouters } from '@/utils/filters'

// Layout
import Layout from '@/layout'
import ManageLayout from '@/layout/manage'

import i18n from '@/utils/i18n'

Vue.use(VueRouter)

const routes = [
  // LIST MENU PAGES
  {
    path: '/',
    component: Layout,
    redirect: `/${getAccountRouters()[0].type}`,
    hidden: false,
    children: getAccountRouters().map((r) => ({
      path: r.type,
      name: r.name,
      component: () => import(`../views/${r.view || r.type}/index`),
      meta: {
        title: r.title,
        icon: r.icon,
        affix: true,
        auth: true,
        data: r,
        roles: ['administrator', 'editor', 'moderator']
      }
    }))
  },

  // SHOW PAGE
  ...getAccountRouters().filter((r) => r.show).map((r) => ({
    path: `/${r.type}/:id`,
    component: Layout,
    hidden: false,
    children: [
      {
        path: '',
        component: () => import(`@/views/${r.view || r.type}/show`),
        name: `${r.name}Info`,
        meta: {
          title: 'Chỉnh sửa thông tin',
          auth: true,
          data: r,
          roles: ['administrator', 'editor', 'moderator']
        }
      }
    ]
  })),

  // DETAIL PAGE
  ...getAccountRouters().filter((r) => r.detail).map((r) => ({
    path: `/${r.type}/:id/detail`,
    component: Layout,
    hidden: false,
    children: [
      {
        path: '',
        component: () => import(`@/views/${r.view || r.type}/detail`),
        name: `${r.name}Detail`,
        meta: {
          title: 'Thông tin chi tiết',
          auth: true,
          data: r,
          roles: ['administrator', 'editor', 'moderator']
        }
      }
    ]
  })),

  // RELATED PAGE
  ...getAccountRouters().filter((r) => r.related).map((r) => ({
    path: `/${r.type}/:id/related`,
    component: Layout,
    hidden: false,
    children: [
      {
        path: '',
        component: () => import(`@/views/${r.view || r.type}/related`),
        name: `${r.name}Related`,
        meta: {
          title: 'Danh sách liên kết',
          auth: true,
          data: r,
          roles: ['administrator', 'editor', 'moderator']
        }
      }
    ]
  })),

  // ACCOUNT INFO
  {
    path: '/information',
    component: Layout,
    hidden: false,
    children: [
      {
        path: '',
        name: 'InformationAccount',
        component: () => import(`../views/information-account/index`),
        meta: {
          title: 'Thông tin tài khoản',
          icon: 'fas fa-user',
          affix: true,
          auth: true,
          roles: ['administrator', 'editor', 'moderator']
        }
      }
    ]
  },

  // BANNER PAGES
  {
    path: '/pages',
    component: Layout,
    hidden: false,
    children: [
      {
        path: '',
        name: 'Pages',
        component: () => import(`../views/pages/index`),
        meta: {
          title: 'Trang Banner',
          icon: 'fas fa-ad',
          affix: true,
          auth: true,
          roles: ['administrator', 'editor', 'moderator']
        }
      },
      {
        path: ':id',
        name: 'PagesInfo',
        component: () => import(`../views/pages/show`),
        meta: {
          title: 'Thông tin Trang Banner',
          affix: true,
          auth: true,
          roles: ['administrator', 'editor', 'moderator']
        }
      },
      {
        path: ':id/detail',
        name: 'PagesDetail',
        component: () => import(`../views/pages/detail`),
        meta: {
          title: 'Nội dung Trang Banner',
          affix: true,
          auth: true,
          roles: ['administrator', 'editor', 'moderator']
        }
      }
    ]
  },

  // MANAGE
  {
    path: '/manage',
    component: ManageLayout,
    hidden: false,
    children: [
      {
        path: 'cook',
        name: 'ManageCook',
        component: () => import(`../views/manage/Cook`),
        meta: {
          title: 'Đầu bếp',
          affix: true,
          auth: true,
          roles: ['administrator', 'editor', 'moderator', 'cook']
        }
      },
      {
        path: 'cashier',
        name: 'ManageCashier',
        component: () => import(`../views/manage/Cashier`),
        meta: {
          title: 'Thu ngân',
          affix: true,
          auth: true,
          roles: ['administrator', 'editor', 'moderator', 'cashier']
        }
      },
      {
        path: 'statistic',
        name: 'ManageStatistic',
        component: () => import(`../views/manage/Statistic`),
        meta: {
          title: 'Thống kê',
          affix: true,
          auth: true,
          roles: ['administrator', 'editor', 'moderator']
        }
      }
    ]
  },

  // AUTH PAGE
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
    name: 'Login',
    meta: { auth: false }
  },

  // PUBLIC PAGES
  {
    path: '/place/:id',
    component: () => import('@/public-views/place/detail'),
    hidden: true,
    name: 'PlaceDetailPublic',
    meta: { auth: false }
  },
  {
    path: '/artifact/:id',
    component: () => import('@/public-views/artifact/detail'),
    hidden: true,
    name: 'ArtifactDetailPublic',
    meta: { auth: false }
  },

  // ERROR PAGES
  {
    path: '/403',
    name: 'Forbidden',
    component: () => import('@/views/errors/E403.vue'),
    meta: { title: i18n.t('errorPage.forbidden.title') }
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import('@/views/errors/E404.vue'),
    meta: { title: i18n.t('errorPage.pageNotFound.title') }
  },
  {
    path: '/500',
    name: 'ServerError',
    component: () => import('@/views/errors/E500.vue'),
    meta: { title: i18n.t('errorPage.serverError.title') }
  },
  {
    path: '/502',
    name: 'BadGateway',
    component: () => import('@/views/errors/E502.vue'),
    meta: { title: i18n.t('errorPage.badGateway.title') }
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
