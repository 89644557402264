const ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL
const PUBLIC_BACKEND_URL = process.env.VUE_APP_PUBLIC_BACKEND_URL

export default {
  // ADMIN ENDPOINT
  AUTH: `${ADMIN_BACKEND_URL}/auth`,
  CONSTANT: `${ADMIN_BACKEND_URL}/constant`,
  ACTIONS: `${ADMIN_BACKEND_URL}/actions`,
  MANAGED_RESOURCES: `${ADMIN_BACKEND_URL}/managed_resources`,

  TAG: `${ADMIN_BACKEND_URL}/tag`,
  USER: `${ADMIN_BACKEND_URL}/user`,
  TOUR: `${ADMIN_BACKEND_URL}/tour`,
  GROUP: `${ADMIN_BACKEND_URL}/group`,
  POST: `${ADMIN_BACKEND_URL}/post`,
  PLACE: `${ADMIN_BACKEND_URL}/place`,
  PACKAGE: `${ADMIN_BACKEND_URL}/package`,
  STORAGE: `${ADMIN_BACKEND_URL}/storage`,
  COMMENT: `${ADMIN_BACKEND_URL}/comment`,
  BANNERS: `${ADMIN_BACKEND_URL}/banners`,
  ARTIFACT: `${ADMIN_BACKEND_URL}/artifact`,
  TOUR_DAY: `${ADMIN_BACKEND_URL}/tour-day`,
  TOUR_DES: `${ADMIN_BACKEND_URL}/tour-des`,
  ORGANIZATION: `${ADMIN_BACKEND_URL}/organization`,
  RECEIPT: `${ADMIN_BACKEND_URL}/receipt`,
  DISH: `${ADMIN_BACKEND_URL}/dish`,
  DISH_GROUP: `${ADMIN_BACKEND_URL}/dish_group`,
  DISH_SUBCRIPTION: `${ADMIN_BACKEND_URL}/dish_subscription`,
  PLACE_RELATED: `${ADMIN_BACKEND_URL}/place_related`,
  DEPARTMENT: `${ADMIN_BACKEND_URL}/department`,
  WORKING_LOCATION: `${ADMIN_BACKEND_URL}/working_location`,
  USER_REGISTER_FORMS: `${ADMIN_BACKEND_URL}/user_register_forms`,

  LIST_TOURS: `${ADMIN_BACKEND_URL}/tour/list`,
  LIST_POSTS: `${ADMIN_BACKEND_URL}/post/list`,
  LIST_PLACES: `${ADMIN_BACKEND_URL}/place/list`,
  LIST_STORAGES: `${ADMIN_BACKEND_URL}/storage/list`,
  LIST_COMMENTS: `${ADMIN_BACKEND_URL}/comment/list`,
  LIST_ARTIFACTS: `${ADMIN_BACKEND_URL}/artifact/list`,
  LIST_TOUR_DAYS: `${ADMIN_BACKEND_URL}/tour-day/list`,
  CHANGE_ORDER: `${ADMIN_BACKEND_URL}/storage/change-order`,
  ORGANIZATIONS: `${ADMIN_BACKEND_URL}/organizations`,
  RECEIPT_STATISTIC: `${ADMIN_BACKEND_URL}/receipt/statistic`,
  CLASSIFICATION: `${ADMIN_BACKEND_URL}/artifact/classification`,

  POST_CATEGORIES: `${ADMIN_BACKEND_URL}/post_categories`,
  TOUR_CATEGORIES: `${ADMIN_BACKEND_URL}/tour_categories`,
  PLACE_CATEGORIES: `${ADMIN_BACKEND_URL}/place_categories`,
  GOONG_AUTOCOMPLETE: `${ADMIN_BACKEND_URL}/goong/autocomplete`,
  GOONG_DETAIL: `${ADMIN_BACKEND_URL}/goong/detail`,

  DISPLAY_MODELS: `${ADMIN_BACKEND_URL}/display_models`,

  HIERARCHY: `${ADMIN_BACKEND_URL}/hierarchy`,
  HIERARCHY_ITEM: `${ADMIN_BACKEND_URL}/hierarchy_item`,

  ORGANIZATION_ACTIONS: `${ADMIN_BACKEND_URL}/organization_actions`,
  ORGANIZATION_ACTION_ASSIGNEES: `${ADMIN_BACKEND_URL}/organization_action_assignees`,

  // PUBLIC ENDPOINT
  PLACE_PUBLIC: `${PUBLIC_BACKEND_URL}/place`,
  ARTIFACT_PUBLIC: `${PUBLIC_BACKEND_URL}/artifact`,
  LIST_STORAGE_PUBLIC: `${PUBLIC_BACKEND_URL}/storage/list`,
  LIST_ARTIFACT_PUBLIC: `${PUBLIC_BACKEND_URL}/artifact/list`
}
