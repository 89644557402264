const state = {
  reloadArtifactGroup: ''
}

const getters = {
  reloadArtifactGroup(state) {
    return state.reloadArtifactGroup
  }
}

const mutations = {
  UPDATE_RELOAD_ARTIFACT_GROUP(state, reloadArtifactGroup) {
    state.reloadArtifactGroup = reloadArtifactGroup
  }
}

const actions = {
  updateReloadArtifactGroup({ commit }, reloadArtifactGroup) {
    commit('UPDATE_RELOAD_ARTIFACT_GROUP', reloadArtifactGroup)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
